/* eslint-disable sonarjs/no-duplicate-string */
import {
  PHOTOGRAPHERS_TAXONOMY_KEY,
  PLANNERS_TAXONOMY_KEY,
  SearchableVendorTaxonomyKey,
  VIDEOGRAPHERS_TAXONOMY_KEY,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

type LocationAndCategory = {
  taxonomyKey: SearchableVendorTaxonomyKey;
  locationSlug: string;
  locationLabel: string;
};

/**
 * Static list of top ~50 location and category combinations
 *
 * @see https://docs.google.com/spreadsheets/d/14O_p3wYJzDE7dT7evtfy5SDhV5ZyZrUjzj41WQjONgA/edit
 */
export const topLocationsAndCategories: LocationAndCategory[] = [
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'chicago-il',
    locationLabel: 'Chicago, IL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'seattle-wa',
    locationLabel: 'Seattle, WA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'las-vegas-nv',
    locationLabel: 'Las Vegas, NV',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'tampa-fl',
    locationLabel: 'Tampa, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'pittsburgh-pa',
    locationLabel: 'Pittsburgh, PA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'los-angeles-ca',
    locationLabel: 'Los Angeles, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'houston-tx',
    locationLabel: 'Houston, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'denver-co',
    locationLabel: 'Denver, CO',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'los-angeles-ca',
    locationLabel: 'Los Angeles, CA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'new-orleans-la',
    locationLabel: 'New Orleans, LA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'chicago-il',
    locationLabel: 'Chicago, IL',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'kansas-city-mo',
    locationLabel: 'Kansas City, MO',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'minneapolis-mn',
    locationLabel: 'Minneapolis, MN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'houston-tx',
    locationLabel: 'Houston, TX',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'tampa-fl',
    locationLabel: 'Tampa, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'new-york-ny',
    locationLabel: 'New York, NY',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'austin-tx',
    locationLabel: 'Austin, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'boston-ma',
    locationLabel: 'Boston, MA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'miami-fl',
    locationLabel: 'Miami, FL',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'orlando-fl',
    locationLabel: 'Orlando, FL',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'atlanta-ga',
    locationLabel: 'Atlanta, GA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cleveland-oh',
    locationLabel: 'Cleveland, OH',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'santa-barbara-ca',
    locationLabel: 'Santa Barbara, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'long-island-ny',
    locationLabel: 'Long Island, NY',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'austin-tx',
    locationLabel: 'Austin, TX',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'denver-co',
    locationLabel: 'Denver, CO',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'fort-worth-tx',
    locationLabel: 'Fort Worth, TX',
  },
  {
    taxonomyKey: VIDEOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'dallas-tx',
    locationLabel: 'Dallas, TX',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'san-francisco-ca',
    locationLabel: 'San Francisco, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'indianapolis-in',
    locationLabel: 'Indianapolis, IN',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'kansas-city-mo',
    locationLabel: 'Kansas City, MO',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cincinnati-oh',
    locationLabel: 'Cincinnati, OH',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'detroit-mi',
    locationLabel: 'Detroit, MI',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'sacramento-ca',
    locationLabel: 'Sacramento, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'baltimore-md',
    locationLabel: 'Baltimore, MD',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'new-york-ny',
    locationLabel: 'New York, NY',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'san-diego-ca',
    locationLabel: 'San Diego, CA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'phoenix-az',
    locationLabel: 'Phoenix, AZ',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'honolulu-hi',
    locationLabel: 'Honolulu, HI',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'atlanta-ga',
    locationLabel: 'Atlanta, GA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'boston-ma',
    locationLabel: 'Boston, MA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'minneapolis-mn',
    locationLabel: 'Minnepolis, MN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'portland-or',
    locationLabel: 'Portland, OR',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'san-diego-ca',
    locationLabel: 'San Diego, CA',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'seattle-wa',
    locationLabel: 'Seattle, WA',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'milwaukee-wi',
    locationLabel: 'Milwaukee, WI',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'cape-cod',
    locationLabel: 'Cape Cod',
  },
  {
    taxonomyKey: PHOTOGRAPHERS_TAXONOMY_KEY,
    locationSlug: 'grand-rapids-mi',
    locationLabel: 'Grand Rapids, MI',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'indianapolis-in',
    locationLabel: 'Indianapolis, IN',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'las-vegas-nv',
    locationLabel: 'Las Vegas, NV',
  },
  {
    taxonomyKey: PLANNERS_TAXONOMY_KEY,
    locationSlug: 'philadelphia-pa',
    locationLabel: 'Philadelphia, PA',
  },
];

type NearMeLink = {
  title: string;
  searchSlug: string;
};

/**
 * Static list of "near me" links for venues
 *
 * @see https://docs.google.com/spreadsheets/d/1YczZTOxL-Z5_HavEZ1ix_qfkYe_hPPPZmXCZ-6l9N7U/edit
 */
export const venuesNearMeLinks: NearMeLink[] = [
  {
    title: 'Outdoor Wedding Venues Near Me',
    searchSlug: 'wedding-venues--outdoor-space',
  },
  {
    title: 'Aquarium & Zoo Wedding Venues Near Me',
    searchSlug: 'wedding-venues--preference-zoos',
  },
  {
    title: 'Ballroom & Banquet Halls Wedding Venues Near Me',
    searchSlug: 'wedding-venues--ballrooms-banquet-halls',
  },
  {
    title: 'Beach & Waterfront Wedding Venues Near Me',
    searchSlug: 'wedding-venues--beaches-waterfronts',
  },
  {
    title: 'Barn & Farm Wedding Venues Near Me',
    searchSlug: 'wedding-venues--farms-barns-ranches',
  },
  {
    title: 'Country Club & Golf Club Wedding Venues Near Me',
    searchSlug: 'wedding-venues--golf-country-clubs',
  },
  {
    title: 'Historic Estate & Mansions Wedding Venues Near Me',
    searchSlug: 'wedding-venues--historic-estates-mansions',
  },
  {
    title: 'Hotel & Resort Wedding Venues Near Me',
    searchSlug: 'wedding-venues--hotels-inns-resorts',
  },
  {
    title: 'Industrial Wedding Venues Near Me',
    searchSlug: 'wedding-venues--industrial-buildings',
  },
  {
    title: 'Retreat Wedding Venues Near Me',
    searchSlug: 'wedding-venues--lodges-retreats',
  },
  {
    title: 'Museum & Gallery Wedding Venues Near Me',
    searchSlug: 'wedding-venues--museums-galleries',
  },
  {
    title: 'Park & Garden Wedding Venues Near Me',
    searchSlug: 'wedding-venues--parks-gardens',
  },
  {
    title: 'Restaurant & Brewery Wedding Venues Near Me',
    searchSlug: 'wedding-venues--restaurants-breweries',
  },
  {
    title: 'Urban Wedding Venues Near Me',
    searchSlug: 'wedding-venues--urban-locations',
  },
  {
    title: 'Vineyard & Winery Wedding Venues Near Me',
    searchSlug: 'wedding-venues--vineyards-wineries',
  },
  {
    title: 'All-Inclusive Wedding Venues Near Me',
    searchSlug: 'wedding-venues--all-inclusive',
  },
  {
    title: 'Raw Wedding Spaces & Venues Near Me',
    searchSlug: 'wedding-venues--raw-space',
  },
  {
    title: 'Wedding Rentals Near Me',
    searchSlug: 'wedding-venues--event-rentals',
  },
  {
    title: 'On-Site Accommodations Wedding Venues Near Me',
    searchSlug: 'wedding-venues--on-site-accomodations',
  },
  {
    title: 'Dog-Friendly Wedding Venues Near Me',
    searchSlug: 'wedding-venues--pet-friendly',
  },
  {
    title: 'Wheelchair & Handicap Accessible Wedding Venues Near Me',
    searchSlug: 'wedding-venues--handicap-accessible',
  },
  {
    title: 'Wedding After-Party Venues Near Me',
    searchSlug: 'wedding-venues--after-hours-events',
  },
  {
    title: 'Venues for Indian Weddings Near Me',
    searchSlug: 'wedding-venues--baraat',
  },
  {
    title: 'Elopement Venues Near Me',
    searchSlug: 'wedding-venues--event-types-elopement',
  },
  {
    title: 'Engagement Party Venues Near Me',
    searchSlug: 'wedding-venues--engagement-celebration',
  },
  {
    title: 'Rehearsal Dinner & Bridal Shower Venues Near Me',
    searchSlug: 'wedding-venues--bridal-showers',
  },
];

/**
 * Static list of "near me" links for non-venues
 *
 * @see https://docs.google.com/spreadsheets/d/1YczZTOxL-Z5_HavEZ1ix_qfkYe_hPPPZmXCZ-6l9N7U/edit
 */
export const vendorsNearMeLinks: NearMeLink[] = [
  // Photographers
  {
    title: 'Affordable Wedding Photographers Near Me',
    searchSlug: 'wedding-photographers?price-max=2750&price-min=0',
  },
  {
    title: 'Engagement Photographers Near Me',
    searchSlug: 'wedding-photographers--engagement-shoot',
  },
  // Beauty professionals
  {
    title: 'Affordable Wedding Hair & Makeup Artists Near Me',
    searchSlug: 'wedding-hair-makeup?price-max=375&price-min=0',
  },
  {
    title: 'Wedding & Bridal Fitness Coaches Near Me',
    searchSlug: 'wedding-hair-makeup--fitness-wellness',
  },
  {
    title: 'Bridal Henna Artists Near Me',
    searchSlug: 'wedding-hair-makeup--henna-art',
  },
  {
    title: 'Bridal Spa Treatments Near Me',
    searchSlug: 'wedding-hair-makeup--spa-treatments',
  },
  {
    title: 'Wedding & Bridal Fashion Stylists Near Me',
    searchSlug: 'wedding-hair-makeup--styling',
  },
  {
    title: 'Saree Draping & Dupatta Draping Services Near Me',
    searchSlug: 'wedding-hair-makeup--dupatta-sari-draping',
  },
  // Bands & DJs
  {
    title: 'Affordable Wedding DJs Near Me',
    searchSlug: 'wedding-bands-djs?price-max=1150&price-min=0',
  },
  {
    title: 'Wedding Musicians Near Me',
    searchSlug: 'wedding-bands-djs--live-solo-performer',
  },
  {
    title: 'Americana Wedding Bands Near Me',
    searchSlug: 'wedding-bands-djs--americana',
  },
  {
    title: 'Arabic DJs for Weddings Near Me',
    searchSlug: 'wedding-bands-djs--arabic-persian',
  },
  {
    title: 'Blues Bands for Weddings Near Me',
    searchSlug: 'wedding-bands-djs--blues',
  },
  {
    title: 'Indian Wedding DJs & Bands Near Me',
    searchSlug: 'wedding-bands-djs--bollywood-bhangra',
  },
  {
    title: 'Country Wedding Bands Near Me',
    searchSlug: 'wedding-bands-djs--country-folk',
  },
  {
    title: 'Disco & Funk Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--funk',
  },
  {
    title: 'EDM & House Wedding DJs Near Me',
    searchSlug: 'wedding-bands-djs--edm-house',
  },
  {
    title: 'Gospel Choirs for Weddings Near Me',
    searchSlug: 'wedding-bands-djs--gospel-christian',
  },
  {
    title: 'Jazz Bands for Weddings Near Me',
    searchSlug: 'wedding-bands-djs--jazz',
  },
  {
    title: 'Latin Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--latin',
  },
  {
    title: 'Soul & Motown Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--motown-soul',
  },
  {
    title: 'R&B Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--r-and-b',
  },
  {
    title: 'Rap & Hip Hop Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--rap-hip-hop',
  },
  {
    title: 'Reggae Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--reggae',
  },
  {
    title: 'Pop & Rock Wedding Bands & DJs Near Me',
    searchSlug: 'wedding-bands-djs--pop-top-40s--rock-and-roll',
  },
  // Florists
  {
    title: 'Affordable Wedding Florists Near Me',
    searchSlug: 'wedding-florists?price-max=3000&price-min=0',
  },
  {
    title: 'Boho Wedding Florists Near Me',
    searchSlug: 'wedding-florists--bohemian',
  },
  {
    title: 'A La Carte Wedding Florists Near Me',
    searchSlug: 'wedding-florists--florist-service-level-a-la-carte',
  },
  {
    title: 'Wholesale Wedding Florists Near Me',
    searchSlug: 'wedding-florists--florist-service-level-wholesale',
  },
  // Caterers
  {
    title: 'Affordable Wedding Caterers Near Me',
    searchSlug: 'wedding-catering?price-max=5800&price-min=0',
  },
  {
    title: 'Food Truck Wedding Catering Near Me',
    searchSlug: 'wedding-catering--food-trucks',
  },
  {
    title: 'African Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-african',
  },
  {
    title: 'BBQ Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-bbq',
  },
  {
    title: 'Cajun Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-cajun',
  },
  {
    title: 'Chinese Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-chinese',
  },
  {
    title: 'Eastern European Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-eastern-european',
  },
  {
    title: 'Farm-to-Table Wedding Catering Near Me',
    searchSlug: 'wedding-catering--farm-to-table',
  },
  {
    title: 'French Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-french',
  },
  {
    title: 'Greek Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-greek',
  },
  {
    title: 'Hawaiian Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-hawaiian',
  },
  {
    title: 'Indian Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-indian',
  },
  {
    title: 'Italian Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-italian',
  },
  {
    title: 'Japanese Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-japanese',
  },
  {
    title: 'Korean Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-korean',
  },
  {
    title: 'Latin American Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-latin-american',
  },
  {
    title: 'Mexican Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-mexican',
  },
  {
    title: 'Middle Eastern Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-middle-eastern',
  },
  {
    title: 'Seafood Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-seafood',
  },
  {
    title: 'Southern Food Wedding Catering Near Me',
    searchSlug: 'wedding-catering--southern',
  },
  {
    title: 'Southwestern Food Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-southwestern',
  },
  {
    title: 'Spanish Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-spanish',
  },
  {
    title: 'Thai Wedding Catering Near Me',
    searchSlug: 'wedding-catering--cuisine-types-thai',
  },
  {
    title: 'Gluten-Free Wedding Catering Near Me',
    searchSlug: 'wedding-catering--gluten-free',
  },
  {
    title: 'Halal Wedding Catering Near Me',
    searchSlug: 'wedding-catering--halal',
  },
  {
    title: 'Kosher Wedding Catering Near Me',
    searchSlug: 'wedding-catering--kosher',
  },
  {
    title: 'Organic Wedding Catering Near Me',
    searchSlug: 'wedding-catering--organic',
  },
  {
    title: 'Vegan Wedding Catering Near Me',
    searchSlug: 'wedding-catering--vegan',
  },
  {
    title: 'Vegetarian Wedding Catering Near Me',
    searchSlug: 'wedding-catering--vegetarian',
  },
  // Wedding planners
  {
    title: 'Affordable Wedding Planners Near Me',
    searchSlug: 'wedding-planners?price-max=2400&price-min=0',
  },
  {
    title: 'Day-Of Wedding Coordinators Near Me',
    searchSlug: 'wedding-planners--day-of-coordination',
  },
  {
    title: 'Destination Wedding Planners Near Me',
    searchSlug: 'wedding-planners--destination-wedding',
  },
  {
    title: 'Elopement & Micro Wedding Planners Near Me',
    searchSlug: 'wedding-planners--elopement',
  },
  // Cakes & desserts
  {
    title: 'Wedding Cookies Near Me',
    searchSlug: 'wedding-cakes-desserts--cookies-and-macarons',
  },
  {
    title: 'Wedding Cupcakes Near Me',
    searchSlug: 'wedding-cakes-desserts--cupcakes',
  },
  {
    title: 'Wedding Donuts Near Me',
    searchSlug: 'wedding-cakes-desserts--donuts',
  },
  {
    title: 'Dairy-Free Wedding Cake Bakeries Near Me',
    searchSlug: 'wedding-cakes-desserts--bakers-dietary-dairy-free',
  },
  {
    title: 'Gluten-Free Wedding Cake Bakeries Near Me',
    searchSlug: 'wedding-cakes-desserts--gluten-free',
  },
  {
    title: 'Nut-Free Wedding Cake Bakeries Near Me',
    searchSlug: 'wedding-cakes-desserts--bakers-dietary-nut-free',
  },
  {
    title: 'Sugar-Free Wedding Cake Bakeries Near Me',
    searchSlug: 'wedding-cakes-desserts--sugar-free',
  },
  {
    title: 'Vegan Wedding Cake Bakeries Near Me',
    searchSlug: 'wedding-cakes-desserts--vegan',
  },
  // Videographers
  {
    title: 'Affordable Wedding Videographers Near Me',
    searchSlug: 'wedding-videographers?price-max=2975&price-min=0',
  },
  {
    title: 'Livestream Wedding Services Near Me',
    searchSlug: 'wedding-videographers--livestream-services',
  },
  // Bar services & beverages
  {
    title: 'Coffee Services for Weddings Near Me',
    searchSlug: 'wedding-bar-services--drink-types-coffee-service',
  },
  {
    title: 'Mobile Bartending Services Near Me',
    searchSlug: 'wedding-bar-services--drink-types-mobile-bar',
  },
  // TODO: Uncomment when officiants and extras are enabled (enableOfficiantsAndExtras)
  // Officiants
  // {
  //   title: 'Affordable Wedding Ceremony Officiants Near Me',
  //   searchSlug: 'wedding-officiants?price-max=500&price-min=0', // TODO: Double-check the price max
  // },
  // {
  //   title: 'Civil Union Wedding Officiants Near Me',
  //   searchSlug: 'wedding-officiants--civil-union',
  // },
  // {
  //   title: 'Elopement Wedding Officiants Near Me',
  //   searchSlug: 'wedding-officiants--elopement',
  // },
  // {
  //   title: 'Non-Religious Wedding Ceremony Officiants Near Me',
  //   searchSlug: 'wedding-officiants--non-religious-ceremony',
  // },
  // {
  //   title: 'Religious Wedding Officiants Near Me',
  //   searchSlug: 'wedding-officiants--religious',
  // },
  // {
  //   title: 'Legal License Signing for Weddings Near Me',
  //   searchSlug: 'wedding-officiants--legal-license-signing',
  // },
  // Extras
  // {
  //   title: 'Affordable Wedding Event Extras Near Me',
  //   searchSlug: 'wedding-extras?price-max=1000&price-min=0', // TODO: Double-check the price max
  // },
  // {
  //   title: 'Wedding Decor and Rentals Near Me',
  //   searchSlug: 'wedding-extras--decor-rentals',
  // },
];

const createEnvironmentFlags = (development = false, staging = false, production = false) => ({
  development,
  staging,
  production,
});

// export const EXAMPLE_FLAG = 'example-flag';
export const SUPERLINK_PUBLIC_QUERY_PARAM = 'superlink-public-query-param';
export const FIND_A_COUPLE_V3_VISIBLE = 'find-a-couple-v3-visible';

/**
 * Example where EXAMPLE_FLAG is a string constant:
 * [EXAMPLE_FLAG]: createEnvironmentFlags(true, true, false),
 */
const flags = {
  // [EXAMPLE_FLAG]: createEnvironmentFlags(true, true, false),
  [SUPERLINK_PUBLIC_QUERY_PARAM]: createEnvironmentFlags(true, true, false), // https://newamsterdamlabs.atlassian.net/browse/CE-2362
  [FIND_A_COUPLE_V3_VISIBLE]: createEnvironmentFlags(true, true, true), // https://newamsterdamlabs.atlassian.net/browse/CE-2914
  updateSegmentEventsPLV: createEnvironmentFlags(true, true, true),
  enableOfficiantsAndExtras: createEnvironmentFlags(true, true, false), // PE-1721 Enable couples to find and inquire with officiants and event extras
};

type Environments = 'development' | 'staging' | 'production';
const getEnvironment = (): Environments => {
  const zolaEnv: string | undefined =
    (typeof window !== 'undefined' && window.zola && window.zola.env) || process.env.ZOLA_ENV;
  switch (zolaEnv) {
    case 'development':
    case 'dev':
      return 'development';
    case 'staging':
    case 'stage':
      return 'staging';
    case 'production':
    case 'prod':
      return 'production';
    default:
      return 'production';
  }
};

type FeatureFlag = keyof typeof flags;

const get = (flag: FeatureFlag): boolean => {
  if (flags[flag]) {
    return Boolean(flags[flag][getEnvironment()]);
  }
  return false;
};

export default {
  get,
  getEnvironment,
};

import { Title2 } from '@zola/zola-ui/src/typography/Headings';
import { useResponsiveDesign } from '@zola/zola-ui/src/contexts/ResponsiveDesignContext/ResponsiveDesignContext';
import staticFlags from '~/libs/universal/utils/staticFlags';
import {
  EXTRAS_TAXONOMY_KEY,
  OFFICIANTS_TAXONOMY_KEY,
  SEARCHABLE_VENDOR_TAXONOMY_KEYS,
  SearchableVendorTaxonomyKey,
} from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import {
  Title,
  VendorCategoriesContainer,
  VendorCategoriesList,
  VendorCategoriesTiles,
} from './VendorCategories.styles';

export const VendorCategories = () => {
  const { isMobile } = useResponsiveDesign();
  const categories: SearchableVendorTaxonomyKey[] = staticFlags.get('enableOfficiantsAndExtras')
    ? Array.from(
        new Set([...SEARCHABLE_VENDOR_TAXONOMY_KEYS, OFFICIANTS_TAXONOMY_KEY, EXTRAS_TAXONOMY_KEY])
      )
    : SEARCHABLE_VENDOR_TAXONOMY_KEYS;
  return (
    <VendorCategoriesContainer>
      <Title2 css={Title} presentation="h3">
        Explore wedding vendors by category
      </Title2>
      {isMobile ? (
        <VendorCategoriesTiles categories={categories} />
      ) : (
        <VendorCategoriesList categories={categories} />
      )}
    </VendorCategoriesContainer>
  );
};
